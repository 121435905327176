<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="FinanceReports"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="financeReportsCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="contract-schedule">{{ $t('message.contractSchedule') }}</v-tab>
                        <v-tab key="invoice-forecast" v-if="$can('read','InvoiceForecast')">{{ $t('message.invoiceForecast') }}</v-tab>
                        <v-tab key="debit-note-schedule">{{ $t('message.debitNoteSchedule') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="financeReportsCurrentTab">
                        <v-tab-item key="contract-schedule-list">
                            <v-card flat>
                                <ContractSchedule/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="invoice-forecast-list" v-if="$can('read','InvoiceForecast')">
                            <v-card flat>
                                <InvoiceForecast/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item  key="debit-note-schedule-list">
                            <v-card flat>
                                <DebitNoteSchedule/>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";


const ContractSchedule = () => import("Components/Appic/ContractSchedule");
const InvoiceForecast = () => import("Components/Appic/InvoiceForecast");
const DebitNoteSchedule = () => import("Components/Appic/DebitNoteSchedule")

export default {
    name: "FinanceReports",
    components: {InvoiceForecast, ContractSchedule, DebitNoteSchedule},
    data() {
        return {
            loader: false,
        }
    },
    computed: {
        ...mapFields('runtime',{
            financeReportsCurrentTab: 'financeReportsCurrentTab'
        })
    }
}
</script>

<style>
</style>